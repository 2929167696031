import React, { useState } from "react";
import { format, parseISO } from "date-fns";
import DarkenedBackgroundImage from "../components/darkened-background-image";
import { graphql, Link } from "gatsby";
import classNames from "classnames";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Button from "../components/button";
import SocialLinks from "../components/social-links";
import Location from "../components/location";
import { ReactComponent as Salmon } from "../images/salmon.svg";
import { ReactComponent as SalmonCowboy } from "../images/salmon-cowboy.svg";
import { ReactComponent as BagelBuzzLogo } from "../images/blog-logo.svg";
import * as styles from "./index.module.css";
import PatternContainer from "../components/pattern-container";
import { GatsbyImage } from "gatsby-plugin-image";

function IndexPage({ data }) {
  const tagline = data.prismicHomepage.data.tagline;
  const imageData = data.prismicHomepage.data.main_image.gatsbyImageData;

  return (
    <Layout>
      <SEO
        title="Home"
        description={data.prismicHomepage.data.seo_description}
        keywords={data.prismicHomepage.data.seo_keywords
          ?.split(",")
          .map(keyword => keyword.trim())}
      />

      <DarkenedBackgroundImage
        Tag="section"
        className="flex flex-col items-center justify-center px-8 text-white"
        image={imageData}
      >
        <h1 className="mt-8 mb-4 md:mt-32">{tagline}</h1>
        <div className="my-4 w-full flex flex-col md:w-auto md:flex-row md:items-center md:justify-center whitespace-no-wrap md:mb-32">
          <Button
            to="/locations"
            variant="white"
            className={classNames("mb-4 md:mb-0 md:mr-4", styles.heroButton)}
          >
            View Locations
          </Button>
        </div>
        <SocialLinks className="mt-4 mb-8" />
      </DarkenedBackgroundImage>
      <Locations locationNodes={data.fullLocations.nodes} />
      <OrderCatering
        backgroundImage={
          data.prismicHomepage.data.delivery_background_image.gatsbyImageData
        }
      />
      <About />
      <WhyYouWillLoveUs
        backgroundImage={
          data.prismicHomepage.data.about_us_background_image.gatsbyImageData
        }
      />

      <PatternContainer
        className={classNames(
          "px-3 py-12 flex flex-col items-center",
          styles.bagelBuzzSection
        )}
      >
        <BagelBuzzLogo className="hidden md:block fill-current text-rosens-marzipan mt-16" />
        <BagelBuzzLogo
          className="md:hidden fill-current text-rosens-marzipan mt-16"
          width="250"
          height="59.93"
          viewBox="0 0 351.17 84.18"
        />
        <BlogPreviews blogPosts={data.blogPosts.nodes} className="mt-16" />
        {/* <Instagram
          url={data.prismicMetadata.data.instagram.url}
          posts={data.instagram.edges.map(edge => edge.node)}
          className="w-full mt-8"
        /> */}
      </PatternContainer>
      <Press press={data.prismicHomepage.data.press} />
    </Layout>
  );
}

function BlogPreviews({ blogPosts, className }) {
  return (
    <section
      className={classNames(
        "flex flex-col max-w-6xl w-full items-center",
        className
      )}
    >
      {blogPosts.map(blogPost => (
        <Link
          to={`/blog/${blogPost.uid}`}
          className="flex flex-wrap w-full mt-8"
        >
          <div className="w-full md:w-1/2">
            {blogPost.data.thumbnail &&
            blogPost.data.thumbnail.gatsbyImageData ? (
              <GatsbyImage
                className="w-full h-full"
                image={blogPost.data.thumbnail.gatsbyImageData}
              />
            ) : (
              <div className="bg-gray-100 w-full h-full" />
            )}
          </div>
          <div className="w-full bg-white text-rosens-green-kelp px-8 py-24 md:w-1/2 md:flex md:flex-col md:justify-center">
            {blogPost.data.date_posted && (
              <div className="text-rosens-dove-gray mb-4 text-sm">
                {format(parseISO(blogPost.data.date_posted), "MMMM d, y")}
              </div>
            )}
            <h3 className="mb-4 text-center md:text-left">
              {blogPost.data.title}
            </h3>
            <p className="mb-4 text-center md:text-left">
              {blogPost.data.blurb}
            </p>
            <div className="text-center md:text-left">
              <Button
                variant="white"
                size="small"
                className="border-2 border-rosens-green-kelp py-2 px-4"
                to={`/blog/${blogPost.uid}`}
              >
                {blogPost.data.call_to_action || "Read More"}
              </Button>
            </div>
          </div>
        </Link>
      ))}
      <Button
        to="/blog"
        size="big"
        variant="transparent"
        className="mt-8 bg-transparent border-white border-2"
        style={{
          width: "fit-content"
        }}
      >
        More Bagel Bytes
      </Button>
    </section>
  );
}

function About() {
  const [cowboyMode, setCowboyMode] = useState(false);

  return (
    <section
      className={classNames(
        "bg-rosens-papaya-whip flex flex-col items-center px-2 py-12",
        styles.aboutSection
      )}
    >
      <div className="flex flex-col justify-center" style={{ height: "84px" }}>
        {cowboyMode ? (
          <SalmonCowboy
            width="63.82"
            height="83.30"
            viewBox="0 0 79.78 104.12"
            className="text-rosens-burning-orange fill-current"
          />
        ) : (
          <Salmon
            width="148.37"
            height="45.03"
            viewBox="0 0 185.46 56.29"
            className="text-rosens-burning-orange fill-current"
          />
        )}
      </div>
      <p className="uppercase text-rosens-green-kelp font-apertura text-center mt-12 text-3xl max-w-5xl leading-loose px-4">
        Rosen's is an{" "}
        <span
          className="underline cursor-pointer transition-colors hover:text-rosens-burning-orange active:text-rosens-burning-orange"
          onClick={() => setCowboyMode(!cowboyMode)}
        >
          Austin
        </span>{" "}
        based bagel shop specializing in traditional bagels, artisanal schmears,
        house-cured lox, and all other bagel related needs
      </p>
    </section>
  );
}

function WhyYouWillLoveUs({ backgroundImage }) {
  return (
    <div className="lg:relative">
      <div
        className={classNames(
          "lg:px-4 lg:absolute lg:w-full",
          styles.whyYoullLoveUs
        )}
      >
        <DarkenedBackgroundImage
          tag="Section"
          outerClassName="max-w-6xl mx-auto w-full"
          className="flex flex-col items-center px-8 text-white py-48 max-w-6xl mx-auto w-full"
          image={backgroundImage}
        >
          <Button
            to="/about"
            size="big"
            variant="burningOrange"
            className="md:bg-transparent md:border-white md:border-2"
          >
            Why You'll Love Us
          </Button>
        </DarkenedBackgroundImage>
      </div>
    </div>
  );
}

function Locations({ locationNodes }) {
  return (
    <section
      className={classNames(
        "flex flex-col bg-white text-rosens-green-kelp px-3 py-12 items-center",
        styles.ourLocationsSection
      )}
    >
      <h2 className="mb-12">Our Locations</h2>
      <div className="flex flex-wrap w-full max-w-6xl justify-center">
        {locationNodes.map(locationNode => (
          <Location
            key={locationNode.uid}
            uid={locationNode.uid}
            locationData={locationNode.data}
            className="w-full md:w-1/3 md:p-2 mb-12"
          />
        ))}
      </div>
      <Button
        to="/locations"
        size="big"
        variant="white"
        className="mt-12 border-2 border-rosens-green-kelp"
      >
        More Locations
      </Button>
    </section>
  );
}

function OrderCatering({ backgroundImage }) {
  return (
    <div className="lg:relative">
      <div
        className={classNames(
          "lg:px-4 lg:absolute lg:w-full",
          styles.orderCatering
        )}
      >
        <DarkenedBackgroundImage
          Tag="section"
          outerClassName="max-w-6xl mx-auto w-full"
          className="flex flex-col items-center px-8 text-white py-24 max-w-6xl mx-auto w-full"
          image={backgroundImage}
        >
          <h2>Enjoy Rosen's from any location</h2>
          <Button
            to="/locations"
            size="big"
            variant="burningOrange"
            className="mt-8 md:bg-transparent md:border-white md:border-2"
          >
            Order Now
          </Button>
        </DarkenedBackgroundImage>
      </div>
    </div>
  );
}

function Press({ press }) {
  return (
    <section className="bg-white text-rosens-green-kelp px-4 py-12">
      <h2>Press</h2>
      {press.map(pressItem => (
        <PressItem key={pressItem.link.url} pressItem={pressItem} />
      ))}
    </section>
  );
}

function PressItem({ pressItem }) {
  return (
    <section className="mt-16">
      <div className="font-proxima-nova text-center">{pressItem.source}</div>
      <div className="font-apertura text-center mt-6 text-xl font-bold tracking-wider leading-relaxed">
        <a href={pressItem.link.url}>{pressItem.headline}</a>
      </div>
    </section>
  );
}

// function Instagram({ posts, url, className }) {
//   return (
//     <div
//       className={classNames(
//         "bg-white p-8 max-w-6xl mx-auto flex flex-col text-rosens-green-kelp items-center",
//         className
//       )}
//     >
//       <InstagramIcon
//         className="fill-current mb-4"
//         width="35"
//         height="35"
//         viewBox="0 0 24 24"
//       />
//       <h3 className="mb-4">@RosensBagelCo</h3>
//       <Button
//         external
//         to={url}
//         size="normal"
//         variant="white"
//         className="border-2 border-rosens-green-kelp mb-4"
//       >
//         Follow Us
//       </Button>
//       <div className="flex flex-wrap items-center w-full">
//         <a
//           href={`https://instagram.com/p/${posts[0].id}`}
//           className="no-underline md:w-1/3 md:pr-2 w-full mb-4"
//         >
//           <Img
//             key={posts[0].id}
//             fluid={posts[0].localFile.childImageSharp.fluid}
//           />
//         </a>
//         <a
//           href={`https://instagram.com/p/${posts[1].id}`}
//           className="no-underline md:w-1/3 md:pr-2 w-full mb-4"
//         >
//           <Img
//             key={posts[1].id}
//             fluid={posts[1].localFile.childImageSharp.fluid}
//           />
//         </a>
//         <a
//           href={`https://instagram.com/p/${posts[2].id}`}
//           className="no-underline md:w-1/3 w-full"
//         >
//           <Img
//             key={posts[2].id}
//             fluid={posts[2].localFile.childImageSharp.fluid}
//           />
//         </a>
//       </div>
//     </div>
//   );
// }

export const query = graphql`
  query HomePageQuery {
    prismicHomepage {
      data {
        seo_keywords
        seo_description
        tagline
        main_image {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        delivery_background_image {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            aspectRatio: 3.235955056
          )
        }
        about_us_background_image {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            aspectRatio: 2.472103004
          )
        }
        about_us_button_label
        press {
          headline
          link {
            url
          }
          source
        }
      }
    }

    prismicMetadata {
      data {
        instagram {
          url
        }
      }
    }

    fullLocations: allPrismicLocations(
      filter: { data: { type: { eq: "Full" } } }
    ) {
      nodes {
        id
        uid
        data {
          image {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 1012
              placeholder: BLURRED
            )
          }
          address1
          address2
          addresslink {
            url
          }
          hours
          name
          phone
          menu_button_label
          menupdf {
            url
          }
          online_ordering_link {
            url
          }
          temporarily_closed
        }
      }
    }

    blogPosts: allPrismicBlogPost(
      limit: 3
      sort: { fields: data___date_posted, order: DESC }
    ) {
      nodes {
        uid
        data {
          title
          blurb
          call_to_action
          date_posted
          thumbnail {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 4160
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`;
export default IndexPage;
