import React from "react";
import classNames from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";

import formatPhone from "../utils/format-phone";
import Button from "./button";

function Location({ locationData, className, allowSideBySide = false }) {
  return (
    <section
      className={classNames(
        className,
        allowSideBySide && "md:flex md:flex-row"
      )}
    >
      <GatsbyImage
        image={locationData.image.gatsbyImageData}
        className={classNames(allowSideBySide && "md:w-1/2")}
      />
      <div className={classNames(allowSideBySide && "md:w-1/2")}>
        <h3 className="mt-6">{locationData.name}</h3>
        {(locationData.address1 || locationData.phone) && (
          <p className="mt-4 font-proxima-nova text-center leading-loose tracking-wider">
            <a
              className="underline"
              href={locationData.addresslink && locationData.addresslink.url}
            >
              {locationData.address1}
              <br />
              {locationData.address2}
            </a>
            <br />
            {locationData.phone && (
              <a className="underline" href={`tel:${locationData.phone}`}>
                {formatPhone(locationData.phone)}
              </a>
            )}
          </p>
        )}
        <p className="mt-4 font-proxima-nova text-center leading-loose tracking-wider">
          {locationData.temporarily_closed ? (
            <span className="font-semibold">TEMPORARILY CLOSED</span>
          ) : (
            locationData.hours
          )}
        </p>
        <div className="flex flex-row justify-center mt-4 pb-4">
          {/* TODO - link to menu PDF */}
          {/* <Button
            to={`/menus/${uid}`}
            size="normal"
            variant="white"
            className="mx-2 border-2 border-rosens-green-kelp"
          >
            View Menu
          </Button> */}
          {locationData.menupdf?.url && (
            <Button
              external
              to={locationData.menupdf.url}
              size="normal"
              variant="white"
              className="mx-2 border-2 border-rosens-green-kelp"
            >
              {locationData.menu_button_label || "View Menu"}
            </Button>
          )}
          {locationData.online_ordering_link &&
            !locationData.temporarily_closed && (
              <Button
                external
                to={locationData.online_ordering_link.url}
                size="normal"
                variant="white"
                className="mx-2 border-2 border-rosens-green-kelp"
              >
                Order Online
              </Button>
            )}
        </div>
      </div>
    </section>
  );
}

export default Location;
